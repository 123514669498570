/* eslint-env serviceworker */

/*
  NOTE: The service worker file lives within the entrypoint directory used by
        parcel. When I tried to locate it alongside the JS entrypoints it ended
        up nested within several subdirectories in dist (e.g. dist/__/__/sw.js).

        This is likely intentional to prevent inadvertently setting an overly
        permissing service worker scope.
*/

// Offline support for Google Analytics
import * as GA from 'workbox-google-analytics'
GA.initialize()

const ASSETS = [
  '/',
  // __precacheManifest is defined by parcel-plugin-service-worker-manifest
  ...self.__precacheManifest.map(entry => entry.url)
]
const CACHE_NAME = `word-sleuth-${new Date().toISOString()}`

// Fetch and cache all static assets
// Do not wait for navigation to activate
self.addEventListener('install', function (event) {
  event.waitUntil(
    caches.open(CACHE_NAME)
      .then(cache => cache.addAll(ASSETS))
      .then(() => self.skipWaiting())
  )
})

// Delete all old caches
// Claim any clients open on other tabs
self.addEventListener('activate', function (event) {
  event.waitUntil(
    caches.keys()
      .then(cacheNames => Promise.all(
        cacheNames.filter(cacheName => cacheName !== CACHE_NAME)
          .map(cacheName => caches.delete(cacheName))
      ))
      .then(() => self.clients.claim())
  )
})

// Cache-first, fall back to network
self.addEventListener('fetch', function (event) {
  // Only cache GET requests to our own domain (i.e. not Google Analytics)
  if (
    event.request.method === 'GET' &&
    new URL(event.request.url).host === self.location.host
  ) {
    event.respondWith(
      caches.open(CACHE_NAME)
        .then(function (cache) {
          return cache.match(event.request, { ignoreSearch: true })
            .then(response => response || fetch(event.request))
            .then(function (response) {
              cache.put(event.request, response.clone())
              return response
            })
        })
    )
  }
})
